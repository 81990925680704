import React from "react"
import SEO from "../../components/seo"
import Header from "../../components/header"

import WorkFooter from "../../components/portfolioItems/worksFooter"


import Herospace from "../../../assets/images/portfolio_items/simple_risk/1-min.png"
import img1 from "../../../assets/images/portfolio_items/simple_risk/2-min.png"
import img2 from "../../../assets/images/portfolio_items/simple_risk/3-min.png"
import img3 from "../../../assets/images/portfolio_items/simple_risk/4-min.png"

export default () => 
<div>
    <SEO title={' ICON Worldwide | SimpleRisk - Drupal Development'} 
    description="Drupal website development for SimpleRisk discover more on ICON's portfolio!"
    canonical={'https://icon-worldwide.com/works/simple-risk'}/>
        <Header bgcolor="#292929"/>
    <div id="portfolio-item">
        <div className="herospace">
            <img src={Herospace} alt=" SimpleRisk, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, Lutheran, Drupal website development"/>
            <div className="title"><h1>SimpleRisk<span></span></h1>
            </div>
        </div>
        <div className="portfolio-inner">
        <p>SimpleRisk is a Risk Management platform based in Austin, Texas.  SimpleRisk provides a full suite of tools designed to protect companies from operational risk, and compliance.  Our fresh rebranding and new Drupal platform boasts a flat, touch-friendly responsive design.</p>
        <div className="portfolio-images">
            <img src={img2} alt=" SimpleRisk, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, SimpleRisk, Drupal website development"/>
            <img src={img1} alt=" SimpleRisk, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, SimpleRisk, Drupal website development"/>
            <img src={img3} alt=" SimpleRisk, ICON Worldwide portfolio, Drupal website development" title="ICON Worldwide portfolio, SimpleRisk, Drupal website development"/>
        </div>
        <WorkFooter previous="lutheran" next="project-firefly"/>
        </div>
    </div>
</div>